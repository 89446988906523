import React from 'react'
import { GatsbyBrowser } from 'gatsby'

import CompensateTheme from './CompensateTheme'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return <CompensateTheme>{element}</CompensateTheme>
}
