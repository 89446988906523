import { ThemeOptions, createTheme } from '@mui/material'
import { ColorPartial } from '@mui/material/styles/createPalette'
import { CSSProperties } from '@mui/material/styles/createTypography'

import tokens from './design-tokens.tokens.json'
import linetoBookWoff from './fonts/lineto-circular-book.woff'
import linetoBookWoff2 from './fonts/lineto-circular-book.woff2'
import linetoBoldWoff from './fonts/lineto-circular-bold.woff2'
import linetoBoldWoff2 from './fonts/lineto-circular-bold.woff2'

// Declare custom component variants: https://material-ui.com/customization/palette/
declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    primary: true
    hero: true
    heroAlt: true // use when the hero card is darker than white
    dark: true
    soft: true
    plain: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    xSmall: true
  }

  interface ButtonPropsVariantOverrides {
    tonal: true
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    dark: true
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    caption2: true
  }
}

// Declare custom colors: https://material-ui.com/customization/palette/
declare module '@mui/material/styles' {
  interface TypographyVariants {
    caption2: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    caption2?: React.CSSProperties
  }

  interface TypeText {
    label?: React.CSSProperties['color']
    placeholder?: React.CSSProperties['color']
  }

  interface ColorAlpha {
    '08%': string
    '12%': string
    '16%': string
    '25%': string
  }
  interface MonochromaticPalette {
    20: string
    40: string
    60: string
    80: string // The main colour for this palette
    100: string
  }

  interface Palette {
    textLight: Partial<TypeText>
    hueGrey: ColorPartial
    primaryTone: ColorPartial
    primaryAlpha: ColorAlpha
    dataViz: {
      orange: ColorPartial
      plum: ColorPartial
      primary: MonochromaticPalette // @deprecated - Use primaryTone
      blue: MonochromaticPalette // @deprecated - Use orange or plum
      gold: MonochromaticPalette // @deprecated - Use orange or plum
    }
  }

  interface PaletteOptions {
    textLight: Partial<TypeText>
    hueGrey: ColorPartial
    primaryTone: ColorPartial
    primaryAlpha: ColorAlpha
    dataViz: {
      orange: ColorPartial
      plum: ColorPartial
      primary: MonochromaticPalette // @deprecated - Use primaryTone
      blue: MonochromaticPalette // @deprecated - Use orange or plum
      gold: MonochromaticPalette // @deprecated - Use orange or plum
    }
  }

  interface TypeBackground {
    paperDark: React.CSSProperties['color']
    ivory: React.CSSProperties['color']
    ice: React.CSSProperties['color']
    foam: React.CSSProperties['color']
  }

  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    xxl: true
  }
}

interface TypeProperty<T> {
  type: string
  value: T
}

interface TypeStyle {
  fontWeight: TypeProperty<number>
  fontSize: TypeProperty<number>
  lineHeight: TypeProperty<number>
  letterSpacing: TypeProperty<number>
  fontStyle: TypeProperty<string>
}

// Only used to reference default theme variables in the `theme` object
const tempTheme = createTheme()

/**
 * Returns a css calculation to support smooth responsiveness between desktop and mobile breakpoints.
 * This prevents the need to define type styles at intermediate breakpoints, since they'll scale automatically.
 * https://medium.com/@jamesweee/minimum-maximum-responsive-font-sizing-in-css-38793acc9f8f
 */
const getResponsiveFormula = (
  min: number,
  max: number,
  mobile: number,
  desktop: number,
) =>
  `calc(${min}px + (100vw - ${mobile}px) / ${desktop - mobile} * ${max - min})`

/**
 * Returns typography styles based on a typography design token definition with
 * mobile and desktop styles. Font size and line height scales automatically
 * between mobile and desktop breakpoints.
 */
const makeTypographyStyle = (
  mobileStyles: TypeStyle,
  desktopStyles: TypeStyle,
): CSSProperties => ({
  // By default, use desktop typography styles
  fontWeight: desktopStyles.fontWeight.value,
  fontSize: desktopStyles.fontSize.value,
  lineHeight: `${desktopStyles.lineHeight.value}px`,
  letterSpacing: desktopStyles.letterSpacing.value,
  fontStyle: desktopStyles.fontStyle.value,
  textTransform: 'none',

  // Below `lg` screen width, make typography responsive between mobile and desktop sizes
  [tempTheme.breakpoints.down('lg')]: {
    fontSize: getResponsiveFormula(
      mobileStyles.fontSize.value,
      desktopStyles.fontSize.value,
      tempTheme.breakpoints.values.sm,
      tempTheme.breakpoints.values.lg,
    ),
    lineHeight: getResponsiveFormula(
      mobileStyles.lineHeight.value,
      desktopStyles.lineHeight.value,
      tempTheme.breakpoints.values.sm,
      tempTheme.breakpoints.values.lg,
    ),
  },

  // Below `sm` screen width, use mobile typography styles
  [tempTheme.breakpoints.down('sm')]: {
    fontWeight: mobileStyles.fontWeight.value,
    fontSize: mobileStyles.fontSize.value,
    lineHeight: `${mobileStyles.lineHeight.value}px`,
    letterSpacing: mobileStyles.letterSpacing.value,
    fontStyle: mobileStyles.fontStyle.value,
  },
})

const theme: ThemeOptions = {
  breakpoints: {
    values: {
      ...tempTheme.breakpoints.values,
      xxl: 1920,
    },
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(78,87,88,0.02),0px 1px 1px 0px rgba(78,87,88,0.01),0px 1px 3px 0px rgba(78,87,88,0.02)',
    '0px 3px 1px -2px rgba(78,87,88,0.02),0px 2px 2px 0px rgba(78,87,88,0.01),0px 1px 5px 0px rgba(78,87,88,0.02)',
    '0px 3px 3px -2px rgba(78,87,88,0.02),0px 3px 4px 0px rgba(78,87,88,0.01),0px 1px 8px 0px rgba(78,87,88,0.02)',
    '0px 2px 4px -1px rgba(78,87,88,0.02),0px 4px 5px 0px rgba(78,87,88,0.01),0px 1px 10px 0px rgba(78,87,88,0.02)',
    '0px 3px 5px -1px rgba(78,87,88,0.02),0px 5px 8px 0px rgba(78,87,88,0.01),0px 1px 14px 0px rgba(78,87,88,0.02)',
    '0px 3px 5px -1px rgba(78,87,88,0.02),0px 6px 10px 0px rgba(78,87,88,0.01),0px 1px 18px 0px rgba(78,87,88,0.02)',
    '0px 4px 5px -2px rgba(78,87,88,0.02),0px 7px 10px 1px rgba(78,87,88,0.01),0px 2px 16px 1px rgba(78,87,88,0.02)',
    '0px 5px 5px -3px rgba(78,87,88,0.02),0px 8px 10px 1px rgba(78,87,88,0.01),0px 3px 14px 2px rgba(78,87,88,0.02)',
    '0px 5px 6px -3px rgba(78,87,88,0.02),0px 9px 12px 1px rgba(78,87,88,0.01),0px 3px 16px 2px rgba(78,87,88,0.02)',
    '0px 6px 6px -3px rgba(78,87,88,0.02),0px 10px 14px 1px rgba(78,87,88,0.01),0px 4px 18px 3px rgba(78,87,88,0.02)',
    '0px 6px 7px -4px rgba(78,87,88,0.02),0px 11px 15px 1px rgba(78,87,88,0.01),0px 4px 20px 3px rgba(78,87,88,0.02)',
    '0px 7px 8px -4px rgba(78,87,88,0.02),0px 12px 17px 2px rgba(78,87,88,0.01),0px 5px 22px 4px rgba(78,87,88,0.02)',
    '0px 7px 8px -4px rgba(78,87,88,0.02),0px 13px 19px 2px rgba(78,87,88,0.01),0px 5px 24px 4px rgba(78,87,88,0.02)',
    '0px 7px 9px -4px rgba(78,87,88,0.02),0px 14px 21px 2px rgba(78,87,88,0.01),0px 5px 26px 4px rgba(78,87,88,0.02)',
    '0px 8px 9px -5px rgba(78,87,88,0.02),0px 15px 22px 2px rgba(78,87,88,0.01),0px 6px 28px 5px rgba(78,87,88,0.02)',
    '0px 8px 10px -5px rgba(78,87,88,0.02),0px 16px 24px 2px rgba(78,87,88,0.01),0px 6px 30px 5px rgba(78,87,88,0.02)',
    '0px 8px 11px -5px rgba(78,87,88,0.02),0px 17px 26px 2px rgba(78,87,88,0.01),0px 6px 32px 5px rgba(78,87,88,0.02)',
    '0px 9px 11px -5px rgba(78,87,88,0.02),0px 18px 28px 2px rgba(78,87,88,0.01),0px 7px 34px 6px rgba(78,87,88,0.02)',
    '0px 9px 12px -6px rgba(78,87,88,0.02),0px 19px 29px 2px rgba(78,87,88,0.01),0px 7px 36px 6px rgba(78,87,88,0.02)',
    '0px 10px 13px -6px rgba(78,87,88,0.02),0px 20px 31px 3px rgba(78,87,88,0.01),0px 8px 38px 7px rgba(78,87,88,0.02)',
    '0px 10px 13px -6px rgba(78,87,88,0.02),0px 21px 33px 3px rgba(78,87,88,0.01),0px 8px 40px 7px rgba(78,87,88,0.02)',
    '0px 10px 14px -6px rgba(78,87,88,0.02),0px 22px 35px 3px rgba(78,87,88,0.01),0px 8px 42px 7px rgba(78,87,88,0.02)',
    '0px 11px 14px -7px rgba(78,87,88,0.02),0px 23px 36px 3px rgba(78,87,88,0.01),0px 9px 44px 8px rgba(78,87,88,0.02)',
    'rgb(78 87 88 / 5%) 0px 6px 6px -3px, rgb(78 87 88 / 4%) 0px 10px 14px 1px, rgb(78 87 88 / 6%) 0px 4px 18px 3px',
  ],

  palette: {
    primary: {
      light: tokens.color.compensate.primary.light.value,
      main: tokens.color.compensate.primary.default.value,
      dark: tokens.color.compensate.primary.dark.value,
      contrastText: tokens.color.compensate.white.value,
    },

    secondary: {
      dark: tokens.color.compensate.gold.value,
      main: tokens.color.compensate.gold.tone['300'].value,
      light: tokens.color.compensate.gold.tone['100'].value,
    },

    error: {
      light: tokens.color.compensate.prompt.error.light.value,
      main: tokens.color.compensate.prompt.error.default.value,
      dark: tokens.color.compensate.prompt.error.dark.value,
      contrastText: tokens.color.compensate.prompt.error.dark.value,
    },

    warning: {
      light: tokens.color.compensate.prompt.warning.light.value,
      main: tokens.color.compensate.prompt.warning.default.value,
      dark: tokens.color.compensate.prompt.warning.dark.value,
      contrastText: tokens.color.compensate.prompt.warning.dark.value,
    },

    info: {
      light: tokens.color.compensate.prompt.info.light.value,
      main: tokens.color.compensate.prompt.info.default.value,
      dark: tokens.color.compensate.prompt.info.dark.value,
      contrastText: tokens.color.compensate.prompt.info.dark.value,
    },

    success: {
      light: tokens.color.compensate.prompt.success.light.value,
      main: tokens.color.compensate.prompt.success.default.value,
      dark: tokens.color.compensate.prompt.success.dark.value,
      contrastText: tokens.color.compensate.prompt.success.dark.value,
    },

    text: {
      primary: tokens.color.compensate.text.title.value,
      secondary: tokens.color.compensate.text.body.value,
      label: tokens.color.compensate.text.label.value,
      placeholder: tokens.color.compensate.text.placeholder.value,
    },

    textLight: {
      primary: tokens.color.compensate.text.titleLight.value,
      secondary: tokens.color.compensate.text.bodyLight.value,
      disabled: tokens.color.compensate.grey['70'].value,
    },

    background: {
      default: tokens.color.compensate.background.mist.value,
      paper: tokens.color.compensate.background.mist.value,
      paperDark: tokens.color.compensate.background.carbon.value,
      ivory: tokens.color.compensate.background.ivory.value,
      ice: tokens.color.compensate.background.ice.value,
      foam: tokens.color.compensate.background.foam.value,
    },

    primaryTone: {
      50: tokens.color.compensate.primary.tone['50'].value,
      100: tokens.color.compensate.primary.tone['100'].value,
      200: tokens.color.compensate.primary.tone['200'].value,
      300: tokens.color.compensate.primary.tone['300'].value,
      400: tokens.color.compensate.primary.tone['400'].value,
      500: tokens.color.compensate.primary.tone['500'].value,
      600: tokens.color.compensate.primary.tone['600'].value,
      700: tokens.color.compensate.primary.tone['700'].value,
      800: tokens.color.compensate.primary.tone['800'].value,
      900: tokens.color.compensate.primary.tone['900'].value,
    },

    primaryAlpha: {
      '08%': tokens.color.compensate.primary.opacity['8'].value,
      '12%': tokens.color.compensate.primary.opacity['12'].value,
      '16%': tokens.color.compensate.primary.opacity['16'].value,
      '25%': tokens.color.compensate.primary.opacity['25'].value,
    },

    hueGrey: {
      50: tokens.color.compensate.grey['95'].value,
      100: tokens.color.compensate.grey['90'].value,
      200: tokens.color.compensate.grey['80'].value,
      300: tokens.color.compensate.grey['70'].value,
      400: tokens.color.compensate.grey['60'].value,
      500: tokens.color.compensate.grey['50'].value,
      600: tokens.color.compensate.grey['40'].value,
      700: tokens.color.compensate.grey['30'].value,
      800: tokens.color.compensate.grey['20'].value,
      900: tokens.color.compensate.grey['10'].value,
    },

    dataViz: {
      orange: {
        500: tokens.color.compensate.orange.tone['500'].value,
        300: tokens.color.compensate.orange.tone['300'].value,
        100: tokens.color.compensate.orange.tone['100'].value,
      },
      plum: {
        500: tokens.color.compensate.plum.tone['500'].value,
        300: tokens.color.compensate.plum.tone['300'].value,
        100: tokens.color.compensate.plum.tone['100'].value,
      },
      // @deprecated
      primary: {
        20: '#3DD5E3',
        40: '#36BDC9',
        60: '#30A5B0',
        80: tokens.color.compensate.primary.default.value,
        100: '#22757D',
      },
      // @deprecated
      blue: {
        20: '#33B1FF',
        40: '#1192E8',
        60: '#0072C3',
        80: '#00539A',
        100: '#003A6D',
      },
      // @deprecated
      gold: {
        20: '#DCD1CB',
        40: '#C8B6AC',
        60: '#B49A8E',
        80: '#A08270',
        100: '#846358',
      },
    },
  },

  typography: {
    fontSize: 16,
    fontWeightRegular: 400,
    fontWeightBold: 700,
    fontFamily:
      '"Lineto Circular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',

    h1: makeTypographyStyle(
      tokens.typography.mobile.display.xLarge,
      tokens.typography.desktop.display.xLarge,
    ),

    h2: makeTypographyStyle(
      tokens.typography.mobile.display.large,
      tokens.typography.desktop.display.large,
    ),

    h3: makeTypographyStyle(
      tokens.typography.mobile.display.medium,
      tokens.typography.desktop.display.medium,
    ),

    h4: makeTypographyStyle(
      tokens.typography.mobile.display.small,
      tokens.typography.desktop.display.small,
    ),

    h5: makeTypographyStyle(
      tokens.typography.mobile.paragraph.largeBold,
      tokens.typography.desktop.paragraph.largeBold,
    ),

    h6: makeTypographyStyle(
      tokens.typography.mobile.paragraph.mediumBold,
      tokens.typography.desktop.paragraph.mediumBold,
    ),

    body1: makeTypographyStyle(
      tokens.typography.mobile.paragraph.medium,
      tokens.typography.desktop.paragraph.medium,
    ),

    body2: makeTypographyStyle(
      tokens.typography.mobile.paragraph.small,
      tokens.typography.desktop.paragraph.small,
    ),

    subtitle1: makeTypographyStyle(
      tokens.typography.mobile.paragraph.large,
      tokens.typography.desktop.paragraph.large,
    ),

    button: makeTypographyStyle(
      tokens.typography.mobile.paragraph.mediumBold,
      tokens.typography.desktop.paragraph.mediumBold,
    ),

    overline: {
      fontWeight: tokens.typography.desktop.caps.fontWeight.value,
      fontSize: tokens.typography.desktop.caps.fontSize.value,
      lineHeight: `${tokens.typography.desktop.caps.lineHeight.value}px`,
      letterSpacing: tokens.typography.desktop.caps.letterSpacing.value,
      fontStyle: tokens.typography.desktop.caps.fontStyle.value,
      textTransform: tokens.typography.desktop.caps.textCase.value,
    },

    caption: makeTypographyStyle(
      tokens.typography.mobile.paragraph.xSmall,
      tokens.typography.desktop.paragraph.xSmall,
    ),

    caption2: {
      fontWeight: tokens.typography.desktop.smallCaps.fontWeight.value,
      fontSize: tokens.typography.desktop.smallCaps.fontSize.value,
      lineHeight: `${tokens.typography.desktop.smallCaps.lineHeight.value}px`,
      letterSpacing: tokens.typography.desktop.smallCaps.letterSpacing.value,
      fontStyle: tokens.typography.desktop.smallCaps.fontStyle.value,
      textTransform: tokens.typography.desktop.smallCaps.textCase.value,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: (theme) => `
          @font-face {
            font-family: 'Lineto Circular';
            font-style: normal;
            font-weight: 400;
            src: url(${linetoBookWoff}) format('woff'), url(${linetoBookWoff2}) format('woff2');
          }

          @font-face {
            font-family: 'Lineto Circular';
            font-style: normal;
            font-weight: 700;
            src: url(${linetoBoldWoff}) format('woff'), url(${linetoBoldWoff2}) format('woff2');
          }

          html {
            background: ${tokens.color.compensate.primary.tone['50'].value};
          }

          body {
            background: transparent;
          }

          @supports (overflow-y: overlay) {
            body {
              overflow-y: overlay;
              scrollbar-gutter: auto;
            }
          }

          #___gatsby {
            background: ${tokens.color.compensate.background.mist.value};
          }

          div#hubspot-messages-iframe-container {
            z-index: ${theme.zIndex.fab};

            ${theme.breakpoints.down('sm')} {
              /* Show chat icon above mobile navigation */
              bottom: 56px !important;
            }
          }
      `,
    },

    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up('xxl')]: {
            '&.MuiContainer-maxWidthXs': {
              maxWidth: theme.breakpoints.values.sm,
            },
            '&.MuiContainer-maxWidthSm': {
              maxWidth: theme.breakpoints.values.md,
            },
            '&.MuiContainer-maxWidthMd': {
              maxWidth: theme.breakpoints.values.lg,
            },
            '&.MuiContainer-maxWidthLg': {
              maxWidth: theme.breakpoints.values.xl,
            },
            '&.MuiContainer-maxWidthXl': {
              maxWidth: theme.breakpoints.values.xxl,
            },
          },
        }),
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: () => ({
          borderWidth: 1,
          '&:first-of-type': { paddingLeft: 0 },
          '&:last-of-type': { paddingRight: 0 },
        }),
        head: () => ({
          paddingTop: 8,
          paddingBottom: 8,
        }),
      },
    },

    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },

    MuiCircularProgress: {
      defaultProps: {
        size: 24,
      },
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // Disable the ripple effect on click
      },

      styleOverrides: {
        root: {
          '&.MuiButton-sizeSmall': makeTypographyStyle(
            tokens.typography.mobile.paragraph.smallBold,
            tokens.typography.desktop.paragraph.smallBold,
          ),
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          },
          '&:focus': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          },
        },
      },
    },

    MuiTooltip: {
      defaultProps: {
        placement: 'top',
        arrow: true,
        enterTouchDelay: 0, // Show tooltips immediately on mobile press,
        leaveTouchDelay: 10000, // Keep tooltips open for 10 seconds on mobile, can also be closed by press off
      },
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.background.paperDark,
        }),

        arrow: ({ theme }) => ({
          color: theme.palette.background.paperDark,
        }),
      },
    },

    MuiAlert: {
      styleOverrides: {
        filledInfo: ({ theme }) => ({
          backgroundColor: theme.palette.info.light,
          color: theme.palette.info.contrastText,
          border: `1px solid ${theme.palette.info.main}`,
        }),

        filledError: ({ theme }) => ({
          backgroundColor: theme.palette.error.light,
          color: theme.palette.error.contrastText,
          border: `1px solid ${theme.palette.error.main}`,
        }),

        filledWarning: ({ theme }) => ({
          backgroundColor: theme.palette.warning.light,
          color: theme.palette.warning.contrastText,
          border: `1px solid ${theme.palette.warning.main}`,
        }),

        filledSuccess: ({ theme }) => ({
          backgroundColor: theme.palette.success.light,
          color: theme.palette.success.contrastText,
          border: `1px solid ${theme.palette.success.main}`,
        }),
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: 'filled',
        InputProps: {
          disableUnderline: true,
        },
        inputProps: {
          maxLength: 300,
        },
      },

      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiFilledInput-root': {
            backgroundColor: theme.palette.primaryAlpha['08%'],

            '&:not(.Mui-disabled):hover': {
              backgroundColor: theme.palette.primaryAlpha['12%'],
            },

            '&::before': {
              borderBottomWidth: 2,
              borderBottomColor: theme.palette.hueGrey['500'],
              borderBottomStyle: 'solid',
              left: 0,
              bottom: 0,
              content: '""',
              position: 'absolute',
              right: 0,
              transition: 'border-bottom-color 200ms',
              pointerEvents: 'none',
            },

            '&.Mui-focused': {
              backgroundColor: theme.palette.primaryAlpha['08%'],

              '&::before': {
                borderBottomColor: theme.palette.hueGrey['900'],
              },
            },
          },
        }),
      },
    },

    MuiDialog: {
      defaultProps: {
        PaperProps: {
          elevation: 20,
        },
      },

      styleOverrides: {
        paper: {
          transform: 'translateZ(0)', // Fix safari bug https://stackoverflow.com/a/58283449
          borderRadius: 16,
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),

          [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          },

          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
          },
        }),
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(3),

          [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
          },

          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
          },
        }),
      },
    },

    MuiFab: {
      variants: [
        {
          props: { color: 'dark' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.background.paperDark,
            color: theme.palette.textLight.primary,
            transition: 'opacity 50ms, background-color 200ms, color 200ms',

            '&.Mui-disabled': {
              backgroundColor: theme.palette.hueGrey['700'],
              color: theme.palette.textLight.disabled,
            },

            '&:hover': {
              backgroundColor: theme.palette.background.paperDark,
              opacity: 0.8,
            },
          }),
        },
      ],
    },

    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },

      variants: [
        {
          props: { size: 'xSmall' },
          style: {
            fontSize: 14,
            lineHeight: '100%',

            // Dupe breakpoint styles to override specificity of makeTypographyStyle
            [tempTheme.breakpoints.down('lg')]: {
              fontSize: 14,
              lineHeight: '100%',
            },

            [tempTheme.breakpoints.down('sm')]: {
              fontSize: 12,
              lineHeight: '100%',
            },
          },
        },
        {
          props: { size: 'xSmall', variant: 'outlined' },
          style: {
            padding: '8px 14px',

            [tempTheme.breakpoints.down('sm')]: {
              padding: '6px 8px',
            },
          },
        },
        {
          props: { variant: 'tonal' },
          style: ({ theme }) => ({
            border: `1px solid ${theme.palette.primaryAlpha['08%']}`,
            color: theme.palette.primaryTone['600'],
            backgroundColor: theme.palette.background.ivory,
          }),
        },
      ],

      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 4,

          '&.Mui-focusVisible': {
            boxShadow: `0 0 0 3px ${theme.palette.primaryAlpha['25%']}`,
          },
        }),

        containedPrimary: ({ theme }) => ({
          '&:active': {
            transition: 'none',
            backgroundColor: theme.palette.primaryTone['800'],
          },
        }),

        outlinedPrimary: ({ theme }) => ({
          borderWidth: 2,
          borderColor: theme.palette.primary.main,

          '&:hover': {
            color: theme.palette.primary.dark,
            borderColor: theme.palette.primary.dark,
          },

          '&:active': {
            transition: 'none',
            color: theme.palette.primaryTone['900'],
            borderColor: theme.palette.primaryTone['900'],
          },
        }),

        textPrimary: ({ theme }) => ({
          '&:hover': {
            color: theme.palette.primary.dark,
            borderColor: theme.palette.primary.dark,
          },

          '&:active': {
            transition: 'none',
            color: theme.palette.primaryTone['900'],
            borderColor: theme.palette.primaryTone['900'],
          },
        }),

        outlined: {
          borderWidth: 2,

          '&:hover': {
            borderWidth: 2,
          },
        },
      },
    },

    MuiAccordion: {
      defaultProps: {
        square: true,
        elevation: 0,
      },

      styleOverrides: {
        root: () => ({
          '&:before': {
            display: 'none',
          },
        }),
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: () => ({
          minHeight: 36,
        }),
        content: ({ theme }) => ({
          flexGrow: 0,
          margin: 0,
          marginRight: theme.spacing(1),
          color: theme.palette.text.secondary,
          '&.Mui-expanded': {
            marginRight: theme.spacing(1),
            color: theme.palette.primary.dark,
          },
        }),
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: theme.spacing(2),
          marginBottom: theme.spacing(2),
        }),
      },
    },

    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
          padding: 0,
          paddingBottom: theme.spacing(0.5),
          minWidth: 0,
          minHeight: theme.spacing(2),
          color: theme.palette.text.label,
          fontWeight: theme.typography.fontWeightRegular,
          '&.Mui-selected': {
            fontWeight: theme.typography.fontWeightBold,
          },
        }),
      },
    },

    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiChip-filledWarning': {
            backgroundColor: theme.palette.warning.dark,
            color: theme.palette.textLight.primary,
          },
          '&.MuiChip-filledInfo': {
            backgroundColor: theme.palette.info.dark,
            color: theme.palette.textLight.primary,
          },
        }),
        icon: () => ({
          marginLeft: 12,
        }),
      },
    },

    MuiRadio: {
      defaultProps: {
        color: 'default',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,

          '&.Mui-checked': {
            color: theme.palette.text.primary,
          },
        }),
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          transform: 'translateZ(0)', // Fix safari bug https://stackoverflow.com/a/58283449
        },
      },
      variants: [
        {
          props: { variant: 'primary' },
          style: ({ theme }) => ({
            background: theme.palette.common.white,
            borderRadius: 32,
            boxShadow: theme.shadows['10'],
          }),
        },
        {
          props: { variant: 'hero' },
          style: ({ theme }) => ({
            background: theme.palette.common.white,
            borderRadius: 32,
            boxShadow: theme.shadows['23'],
          }),
        },
        {
          props: { variant: 'heroAlt' },
          style: ({ theme }) => ({
            background: theme.palette.common.white,
            borderRadius: 32,
            boxShadow: theme.shadows['24'],
          }),
        },
        {
          props: { variant: 'dark' },
          style: ({ theme }) => ({
            background: theme.palette.background.paperDark,
            color: theme.palette.textLight.primary,
            borderRadius: 32,
          }),
        },
        {
          props: { variant: 'soft' },
          style: ({ theme }) => ({
            background: theme.palette.background.ivory,
            borderRadius: 16,
          }),
        },
        {
          props: { variant: 'plain' },
          style: ({ theme }) => ({
            background: theme.palette.common.white,
            borderRadius: 32,
          }),
        },
        {
          props: { variant: 'outlined' },
          style: ({ theme }) => ({
            background: theme.palette.common.white,
            border: '1px solid',
            borderColor: theme.palette.hueGrey[100],
            borderRadius: 32,
          }),
        },
      ],
    },

    MuiSwitch: {
      defaultProps: {
        color: 'default',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          width: 28,
          height: 16,
          padding: 0,
          display: 'flex',
          '&:active': {
            '& .MuiSwitch-thumb': {
              width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
              transform: 'translateX(9px)',
            },
          },
          '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
              transform: 'translateX(12px)',
              color: theme.palette.grey[100],
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
              },
            },
          },
          '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 12,
            height: 12,
            borderRadius: 6,
            transition: theme.transitions.create(['width'], {
              duration: 200,
            }),
          },
          '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,.35)'
                : 'rgba(0,0,0,.25)',
            boxSizing: 'border-box',
          },
        }),
      },
    },
  },
}

export default createTheme(theme)
