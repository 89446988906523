import React from 'react'
import { GatsbyBrowser } from 'gatsby'
import { CacheProvider } from '@emotion/react'

import getEmotionCache from './getEmotionCache'

const cache = getEmotionCache()

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return <CacheProvider value={cache}>{element}</CacheProvider>
}
